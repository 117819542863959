import React from "react";
import Pages from "pages/Pages";



function App() {

  return (
    <div className="app-pages">

      <Pages />

    </div>
  );
}

export default App;
